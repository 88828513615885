import { Component } from '@angular/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { MenuController } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private menu: MenuController) {
    this.initializeApp();
    this.checkDarkTheme();
  }

  checkDarkTheme() {
    const prefersDarkMode = window.matchMedia(
      '(prefers-color-scheme:dark)'
    ).matches;
    if (prefersDarkMode) {
      document.body.classList.toggle('dark');
    }
  }

  async initializeApp() {
    await SplashScreen.show({
      showDuration: 3000,
      autoHide: true,
    });
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }
}
